type ChatbotType = 'zuzug'

type UseChatbot = {
  setActiveChatbot: (chatbot: ChatbotType | null) => void
  activeChatbot: Ref<ChatbotType | null>
}

export default function (): UseChatbot {
  const activeChatbot = useState<ChatbotType | null>(
    'activeChatbot',
    () => null,
  )

  function setActiveChatbot(chatbot: ChatbotType | null) {
    activeChatbot.value = chatbot
  }

  return {
    setActiveChatbot,
    activeChatbot,
  }
}
